<template>
  <div>
    <div v-if="!showDEs" class="text-center">
      <Loading />
    </div>
    <div v-if="showDEs">     
      <label v-if="rejectionsRequired > 0" class="text-bold">
        {{rejectionsRequired}} Diligent Effort Confirmation{{rejectionsRequired === 1 ? ' is' : 's are'}} required for Risk Locations in the State of {{state}}.
      </label>
      <label v-if="rejectionsRequired == 0" class="text-bold">
        No Diligent Effort Confirmations are required for Risk Locations in the State of {{state}}.
      </label>
      <br><br>
      <ValidationObserver tag="span" v-slot="{errors}">
        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel v-for="(d, index) in newValue" :key="index">
            <v-expansion-panel-header>
              <h5 :class="{'hasError': panelHasError(errors, index)}">Diligent Effort Confirmation #{{index + 1}}</h5>
              <v-spacer />
              
              <v-btn color="#fdb714" type="button" icon x-small height="30" width="30" class="ml-1 mr-2"
                style="flex: none;"
                v-show="showToggleDE(d)"
                @click.stop="toggleSaveDE(d)">
                  <div v-show="deIsSaved(d)" title="Remove from Saved Templates">
                    <i class="fas fa-star" style="font-size: 20px;"></i>
                  </div>
                  <div v-show="!deIsSaved(d)" title="Add to Saved Templates">
                    <i class="fal fa-star" style="font-size: 20px;"></i>
                  </div>
              </v-btn>

            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DiligentEffort 
                v-bind="{
                  value: d,
                  index: index,
                  disabled: disabled,
                  channel: channel,
                  otherDEs: newValue.filter((o,i) => (i != index)),
                  insuranceCarrierOptions: insuranceCarrierOptions
                }"
                @change="updateDE($event, index)" 
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </ValidationObserver>
      
      <ErrorMessages :errors="errors"></ErrorMessages>
    </div>
  </div>
</template>

<script>
  import DiligentEffort from '@/components/form/DiligentEffort.vue'
  import { SysService } from '@/api/SysService'

  export default {
    name: 'DiligentEffortList',
    components: {
      DiligentEffort
    },
    data() {
      return {
        //rejectionsRequired: -1
        rejectionsRequired: 1,
        newValue: [],
        panel: [],
        state: null,
        showDEs: false,
        savedDEs: [],
        insuranceCarrierOptions: []
      }
    },
    props: {
      value: {
        type: Array,
        default: () => []
      },
      errors: {
        type: Array,
        default: () => []
      },
      disabled: Boolean,
      channel: String
    },
    emits: [
      'change'
    ],
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          if (newVal && newVal.length) {
            this.newValue = newVal;
          } else {
            this.newValue = [];
          }
        }
      }
    },
    computed: {

    },
    methods: {
      getSelectedQuote() {
        return { ...this.$store.getters["additionalForm/getSelectedQuote"] }
      },
      showToggleDE(d) {
        let show = false;
        if (
          d && d.Company && d.Company.InsuranceCarrierCode &&
          d.ContactName &&
          d.ContactPhone
        ) {
          show = true;
        }
        return show;
      },
      deIsSaved(d) {
        let saved = false;
        if (
          this.savedDEs &&
          this.savedDEs.length &&
          d.Company &&
          d.Company.InsuranceCarrierCode &&
          this.savedDEs.find(i => i.Company.InsuranceCarrierCode == d.Company.InsuranceCarrierCode)
        ) {
          saved = true;
        }
        return saved;
      },
      toggleSaveDE(d) {
        if (this.deIsSaved(d)) {
          //alert('removing');
          this.$store.dispatch('removeSavedDiligentEffort', d)
        } else {
          //alert('adding');
          const v = { ...d }
          delete v.ContactedDate;
          delete v.DeclineReason;
          this.$store.dispatch('addSavedDiligentEffort', v)
        }
        this.$forceUpdate();
      },
      updateDE($event, index) {
        this.newValue[index] = $event;
        this.$forceUpdate();
        this.$emit("change", this.newValue);
      },
      openAll() {
        for (let i = 0; i < this.newValue.length; i++) {
          this.panel.push(i);
        }
      },
      openFirst() {
        if (this.rejectionsRequired > 0) {
          this.panel = [0];
        }
      },
      panelHasError(errors, index) {
        if (!errors) return false;

        let e = false;
        const keys = Object.keys(errors);
        keys.forEach((k) => {
          if (errors[k].length > 0) {
            const i = k.split('_')[1];
            if (i == index) e = true;
          }
        });
        return e;
      },
      loadSavedDEs() {
        if (this.savedDEs && this.savedDEs.length > 0) {
          this.newValue.forEach((item, index) => {
            if (this.savedDEs.length > index && this.savedDEs[index]) {

              const saved = { ...this.savedDEs[index] };

              const v = {
                Company: saved.Company,
                ContactName: saved.ContactName,
                ContactPhone: saved.ContactPhone,
                ContactEmail: saved.ContactEmail,
                ContactWebsite: saved.ContactWebsite
              };

              this.newValue[index] = v;
            }
          })
        }
        this.$forceUpdate();
      },
      initialize() {

        this.showDEs = true;

        if (this.newValue.length < this.rejectionsRequired) {
          for (let i = 0; i < this.rejectionsRequired; i++) {
            if (!this.newValue[i]) this.newValue.push({});
          }
        }

        this.$store.watch(() => {
          const newDEs = this.savedDEs.length != this.$store.getters.getSavedDiligentEfforts;
          this.savedDEs = this.$store.getters.getSavedDiligentEfforts;
          if (newDEs) this.$forceUpdate();

          if (!this.newValue.find(i => i.Company)) {
            this.loadSavedDEs();
          }

        }, () => { },
          {
            immediate: true,
            deep: true
          });



        this.openAll();
        setTimeout(() => {
          this.openFirst();
        }, 100)


        this.$emit('change', this.newValue)
      }
    },
    created() {
      const risk = this.$store.getters.getControlValue('additionalForm', 'Risk')
      if (risk) {
        this.state = risk.State;
        var quote = this.getSelectedQuote();{
          SysService.state()
            .then((data) => {
              const s = data.find(o => o.StateCode == this.state);
              if (s) {
                this.rejectionsRequired = s.RequiredAuthorizedMarketRejections;
              }

              this.initialize();
            })
        }

      } else {
        this.initialize();
      }

      SysService.insuranceCarrier({
        insuranceRegulatoryTypeCode: 'A'
      })
        .then((data) => {
          this.insuranceCarrierOptions = data
            .filter(o => o.InsuranceCarrierCode != 'P' && o.InsuranceCarrierCode != 'UnkA')
            .map(o => {
              return o
            });
        })

    }
  }
</script>

<style scoped lang="scss">
@import '@/style.scss';

  .hasError {
    color: $tertiary-red;
    font-weight: bold;
  }
</style>
